import moment from 'moment';
import { useContext } from 'react';
import { GlobalData } from '../GlobalData';
import * as XLSX from 'xlsx';

export const reorderDate = (inputDate) => {
  // Parse the input date using Moment.js
  const parsedDate = moment(inputDate, "YYYY-MM-DD");
  // Format the date in the desired format
  return inputDate ? parsedDate.format("DD-MM-YYYY") : "";
};

export const getComas = (value, crb = false, formatVal = true) => {
  return (
    crb ?
        value ? value < 0 ? '(' + Math.abs(Number(value)).toLocaleString() + ')' : Number(value)?.toLocaleString() : ''
      :
        value ? (formatVal ? Number(value)?.toLocaleString() : Number(value)) : ''
  );
};
export const formatTime = (dateString) => {
  return new Date(dateString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});
};

export const $ = (n) => document.getElementsByName(n)[0];

export const isNumeric = (num) => !isNaN(num);
export const getDecimals = () => {
  return localStorage.getItem("Decimals" || 0)
}

export const getCurBranchId = () => {
    return localStorage.getItem("BranchId") || 1;
};
export const getCurBranchName = () => {
    return localStorage.getItem("BranchName") || "Head Office";
};

export const isValidDate = (dateString) => {
  // Check if the date string is in the YYYY-MM-DD format
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  // Create a Date object from the string
  const date = new Date(dateString);

  // Check if the Date object is valid (i.e. not NaN)
  // AND if the year, month, and day extracted from the Date object match the original string
  const year = date.getFullYear();
  const month = date.getMonth() + 1; //getMonth() returns 0-11
  const day = date.getDate();

  return dateString === `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
}

export const useCompType = () => {
  const datacontext = useContext(GlobalData);
  return datacontext?.acSettings[0]?.CompType
};

export const handleExportToExcel = (rptName, arr) => {

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(arr);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const dateStamp = new Date().toISOString().slice(0, 10);
  XLSX.writeFile(workbook, `${rptName}-${dateStamp}.xlsx`);
}
