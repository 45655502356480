//import useState hook to create menu collapse state
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  SubMenu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

//import icons from react icons
import {
  FaWarehouse,
  FaPlus,
  FaBook,
  FaShoppingBag,
  FaBars,
  FaCalculator,
  FaAngleRight,
  FaSignInAlt,
  FaUserPlus,
  FaFileAlt,
  FaAngleDoubleRight,
  FaSchool,
} from "react-icons/fa";
import {
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";
// import { RiPencilLine } from "react-icons/ri";
// import { BiCog } from "react-icons/bi";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "./SideBar.css";
import { GlobalData } from "../GlobalData";
// import { Collapse } from "react-bootstrap";

const SideBar = ({ menu, setMenu, ClearApiData }) => {
  const navigate = useNavigate();
  const [menuwidth, setMenuWidth] = useState(0);
  const contextdata = useContext(GlobalData);

  function SignOut() {
    if (localStorage.getItem("user")) {
      let company = localStorage.getItem("company");
      localStorage.clear();
      setMenu([]);
      ClearApiData();
      localStorage.setItem("company", company);
    }
    navigate("/login");
  }

  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(true);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    // menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setMenuWidth(0);
    } else {
      setMenuCollapse(true);
      setMenuWidth(1);
    }
  };
  useEffect(() => {
    if (menuCollapse === true) {
      setMenuWidth(0);
    } else {
      setMenuWidth(1);
    }
  }, [menuCollapse]);

  // function RoutingFun(url) {
  //     console.log(url);
  //     window.open(url, "_blank")
  // }

  return (
    <>
      <div onClick={menuIconClick} className="mobile_btn">
        <FaBars onClick={menuIconClick} />
      </div>
      <div
        id="header"
        style={{ width: `${menuwidth !== 0 ? "200px" : "41px"}` }}
      >
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse} style={{ background: "orange" }}>
          <SidebarHeader>
            <div className="logotext">
              {/* small and big change using menucollapse state */}
              <p>
                {menuCollapse
                  ? contextdata.firm[0]?.Abbr
                  : contextdata.firm[0]?.label}
              </p>
            </div>
            <div className="closemenu" onClick={menuIconClick}>
              {/* changing menu collapse icon on click */}
              {menuCollapse ? <FiArrowRightCircle size={25} /> : <FiArrowLeftCircle size={25} />}
            </div>
          </SidebarHeader>
          <SidebarContent
            className={`sidebar1 ${menuCollapse ? "collapsed" : ""}`}
          >
            <Menu iconShape="square">
              {localStorage.getItem("user") && (
                <MenuItem active={false} icon={<FiHome />}>
                  Dashboard
                  <Link to="/" />
                </MenuItem>
              )}
              {localStorage.getItem("user") && menu.includes("Coding") && (
                <SubMenu
                  title="Coding"
                  icon={<FaPlus />}
                  className="hover_underline"
                >
                  {menu.includes("Party Coding") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Party Coding
                      <Link to="/partycoding" />
                    </MenuItem>
                  )}
                  {menu.includes("Product Coding") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Product Coding
                      <Link to="/prodcoding" />
                    </MenuItem>
                  )}
                  {menu.includes("Category Coding") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Category Coding
                      <Link to="/digcategcoding" />
                    </MenuItem>
                  )}
                  {menu.includes("Units Coding") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Units Coding
                      <Link to="/unitscoding" />
                    </MenuItem>
                  )}
                  {menu.includes("Brokers Coding") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Brokers Coding
                      <Link to="/brokerscoding" />
                    </MenuItem>
                  )}
                  {menu.includes("Supplier Coding") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Supplier Coding
                      <Link to="/suppliercoding" />
                    </MenuItem>
                  )}
                  {menu.includes("Loom Coding") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Loom Coding
                      <Link to="/loomcoding" />
                    </MenuItem>
                  )}
                  {menu.includes("MendType Coding") && (
                    <MenuItem icon={<FaAngleRight />}>
                      MendType Coding
                      <Link to="/mendtypecoding" />
                    </MenuItem>
                  )}
                  {menu.includes("Dig Reports") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Digital List Rate
                      <Link to="/digratelistsum" />
                    </MenuItem>
                  )}

                  <SubMenu
                    title="Opening"
                    icon={<FaPlus className="plusiconleft" />}
                    className="hover_underline_submenu"
                  >
                    {menu.includes("Party Opening") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Party Opening
                        <Link to="/partyopening" />
                      </MenuItem>
                    )}
                    {menu.includes("Cheques Opening") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Cheques Opening
                        <Link to="/cbpopening" />
                      </MenuItem>
                    )}
                    {menu.includes("Stock Opening") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Stock Opening
                        <Link to="/stockopening" />
                      </MenuItem>
                    )}
                    {menu.includes("Yarn Stock Opening") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Yarn Stock Opening
                        <Link to="/ypvo/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Yarn In Opening") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Yarn Stock Opening (YSO)
                        <Link to="/yso/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Weaving Greige Contract Opening") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Weaving Greige Contract Opening
                        <Link to="/wgco/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Stock Opening") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Stock Opening
                        <Link to="/wgpo/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Digital Opening") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Digital Opening
                        <Link to="/dpvo/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Store Opening") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Store Opening
                        <Link to="/digopening" />
                      </MenuItem>
                    )}
                    {menu.includes("PO Opening") && (
                      <MenuItem icon={<FaAngleRight />}>
                        PO Opening
                        <Link to="/poopening" />
                      </MenuItem>
                    )}
                    {menu.includes("Sale Commission Rates") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Sale Commission
                        <Link to="/salecom" />
                      </MenuItem>
                    )}
                  </SubMenu>
                </SubMenu>
              )}
              {localStorage.getItem("user") &&
                menu.includes("Institute Module") && (
                  <SubMenu
                    title="Institute Forms"
                    icon={<FaSchool color="red" />}
                    className="hover_underline"
                  >
                    {!menu.includes("Student Coding") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Student Coding
                        <Link to="/studentcoding" />
                      </MenuItem>
                    )}
                    {!menu.includes("Course Coding") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Course Coding
                        <Link to="/coursecoding" />
                      </MenuItem>
                    )}
                    {!menu.includes("Student Fees") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Students Fees
                        <Link to="/sr/0" />
                      </MenuItem>
                    )}
                  </SubMenu>
                )}
              {localStorage.getItem("user") && menu.includes("Members") && (
                <SubMenu
                  title="Members"
                  icon={<FaSchool color="teal" />}
                  className="hover_underline"
                >
                  {menu.includes("Member Coding") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Member Coding
                      <Link to="/membercoding" />
                    </MenuItem>
                  )}
                  {menu.includes("Member Payments") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Member Payments
                      <Link to="/mr/0" />
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {localStorage.getItem("user") && menu.includes("A/C Forms") && (
                <SubMenu
                  title="A/C Forms"
                  icon={<FaBook />}
                  className="hover_underline"
                >
                  {menu.includes("Cheques(CPB)") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Party Cheques (CPB)
                      <Link to="/chq/0" />
                    </MenuItem>
                  )}
                  {menu.includes("JV") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Journal Voucher (JV)
                      <Link to="/jv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("BR") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Bank Receipt (BR)
                      <Link to="/br/0" />
                    </MenuItem>
                  )}
                  {menu.includes("BP") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Bank Payment (BP)
                      <Link to="/bp/0" />
                    </MenuItem>
                  )}
                  {menu.includes("CR") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Cash Receipt (CR)
                      <Link to="/cr/0" />
                    </MenuItem>
                  )}
                  {menu.includes("CP") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Cash Payment (CP)
                      <Link to="/cp/0" />
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {localStorage.getItem("user") && menu.includes("Stk Forms") && (
                <SubMenu
                  // suffix={<span className="badge yellow">3</span>}
                  title="Stk Forms"
                  icon={<FaShoppingBag />}
                  className="hover_underline"
                >
                  {contextdata?.acSettings[0]?.PurThroughPO ?
                    (menu.includes("PO") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Purchase Order (PO)
                        <Link to="/po/0" />
                      </MenuItem>
                    )) : ""}
                  {menu.includes("Digital PV") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Store IGP
                      <Link to="/dpv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Digital IGP") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Digital IGP
                      <Link to="/digp/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Digital Processing") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Digital Processing
                      <Link to="/prc/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Digital Packing") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Stitch IGP
                      <Link to="/tr1/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Digital Transfer") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Digital Transfer
                      <Link to="/tr1/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Digital Packing") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Packing Voucher
                      <Link to="/tr2/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Digital Packing") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Store OGP
                      <Link to="/dsv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Digital Packing") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Purchase Return OGP (W/T BarCode)
                      <Link to="/dprv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Digital Packing") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Purchase Return OGP (W/O BarCode)
                      <Link to="/dprv2/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Digital Sale Voucher") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Digital Sales Voucher
                      <Link to="/dsv2/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Digital Packing") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Store Consumption Voucher
                      <Link to="/dcon/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Greige In Out") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Greige Purchase/Sale
                      <Link to="/gps/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Purchase") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Purchase (IGP)
                      <Link to="/pv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Pur Return") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Purchase Return
                      <Link to="/prv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Sales Order") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Sale Order
                      <Link to="/so/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Outward Gate Pass") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Outward Gate Pass
                      <Link to="/ogp/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Sale") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Sale (DO)
                      <Link to="/sv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("POS") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Outlet Return IGP
                      <Link to="/orv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("POS") && (
                    <MenuItem icon={<FaAngleDoubleRight />}>
                      Outlet Transfer Voucher
                      <Link to="/otv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("POS") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Point Of Sale
                      <Link to="/pos/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Sale Return") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Sale Return
                      <Link to="/srv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("IGP") && (
                    <MenuItem icon={<FaAngleRight />}>
                      IGP
                      <Link to="/igp/0" />
                    </MenuItem>
                  )}
                  {menu.includes("BOM") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Bill of Material (BOM)
                      <Link to="/bom/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Sales Order") && (
                    <MenuItem icon={<FaAngleRight />}>
                      <span className="text-danger">Pending Sales Order</span>
                      <Link to="/so_bal" />
                    </MenuItem>
                  )}
                  {menu.includes("Outward Gate Pass") && (
                    <MenuItem icon={<FaAngleRight />}>
                      <span className="text-danger">Pending Gate Passes</span>
                      <Link to="/ogp_bal" />
                    </MenuItem>
                  )}
                  {menu.includes("Stock In Transit") && (
                    <MenuItem icon={<FaAngleRight />}>
                      <span className="text-danger">Stock In Transit</span>
                      <Link to="/stockintransit" />
                    </MenuItem>
                  )}
                  {menu.includes("Stock At Yard") && (
                    <MenuItem icon={<FaAngleRight />}>
                      <span className="text-danger">Stock At Yard</span>
                      <Link to="/stockatyard" />
                    </MenuItem>
                  )}

                </SubMenu>
              )}

              {localStorage.getItem("user") && menu.includes("Gate Forms") && (
                <SubMenu
                  // suffix={<span className="badge yellow">3</span>}
                  title="Gate Forms"
                  icon={<FaShoppingBag />}
                  className="hover_underline"
                >
                  {menu.includes("Factory IGP") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Factory IGP
                      <Link to="/fgp/0" />
                    </MenuItem>
                  )}
                </SubMenu>
              )}

              {localStorage.getItem("user") && menu.includes("Yarn Forms") && (
                <SubMenu
                  title="Yarn Forms"
                  icon={<FaBook />}
                  className="hover_underline"
                >
                  {menu.includes("Yarn Purchase Contract") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Yarn Purchase Contract (YPC)
                      <Link to="/ypc/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Yarn Purchase Voucher") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Yarn Purchase Voucher (YPV)
                      <Link to="/ypv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Yarn Purch Contract") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Yarn Purch Contract (YPCT)
                      <Link to="/ypct/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Yarn In Voucher") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Yarn Stock Purchase (YSP)
                      <Link to="/ysp/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Yarn Sale Contract") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Yarn Sale Contract (YSCT)
                      <Link to="/ysct/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Yarn Out Voucher") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Yarn Stock Voucher (YSS)
                      <Link to="/yss/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Yarn Transfer Voucher") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Yarn Transfer Voucher (YTV)
                      <Link to="/ytv/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Yarn Sale Voucher") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Yarn Sale Voucher (YSV)
                      <Link to="/ysv/0" />
                    </MenuItem>
                  )}
                </SubMenu>
              )}

              {localStorage.getItem("user") &&
                menu.includes("Greige Forms") && (
                  <SubMenu
                    // suffix={<span className="badge yellow">3</span>}
                    title="Greige Forms"
                    icon={<FaShoppingBag />}
                    className="hover_underline"
                  >
                    {menu.includes("Weaving Greige Contract") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Weaving Greige Contract
                        <Link to="/wgc/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Weaving Greige Contract") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Weaving Greige Received
                        <Link to="/wgw/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Sizing Bill") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Sizing Bill
                        <Link to="/wsb/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Weaving Greige Purchase") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Purchase
                        <Link to="/wgp/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Weaving Greige Sale") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Sale
                        <Link to="/wgs/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Weaving Greige Transfer") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Transfer
                        <Link to="/wgt/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Kachi Parchi") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Kachi Parchi
                        <Link to="/kp/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Pakki Parchi") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Pakki Parchi
                        <Link to="/pp/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Received Form") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Received
                        <Link to="/gpv/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Issue Form") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Issue
                        <Link to="/giv/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Fresh Sales Form") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Fresh Bill
                        <Link to="/fsv/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Store Purchase Form") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Store Purchase
                        <Link to="/dpv/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Store Purchase Return Form") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Store Purchase Return
                        <Link to="/dprv2/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Store Consumption Form") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Store Consumption
                        <Link to="/dcon/0" />
                      </MenuItem>
                    )}
                  </SubMenu>
                )}
              {localStorage.getItem("user") && menu.includes("A/C Reports") && (
                <SubMenu
                  // suffix={<span className="badge yellow">3</span>}
                  title="A/c Reports"
                  icon={<FaCalculator />}
                  className="hover_underline"
                >
                  {menu.includes("A/C Statement") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Account Statement
                      <Link to="/acstat" />
                    </MenuItem>
                  )}
                  {menu.includes("Trial") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Trial Balance
                      <Link to="/trial" />
                    </MenuItem>
                  )}
                  {menu.includes("Trial 2") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Trial Balance 2
                      <Link to="/trial2" />
                    </MenuItem>
                  )}
                  {menu.includes("Daily Register") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Daily Register
                      <Link to="/dailyreg" />
                    </MenuItem>
                  )}
                  {menu.includes("Cheques Balances") && (
                    <MenuItem icon={<FaAngleRight />}>
                      CPB Balances
                      <Link to="/cpb_bals" />
                    </MenuItem>
                  )}
                  {menu.includes("Cheques Ledger") && (
                    <MenuItem icon={<FaAngleRight />}>
                      CPB Ledger
                      <Link to="/cpbleg/0" />
                    </MenuItem>
                  )}
                  {menu.includes("AgingDr") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Aging Receivables
                      <Link to="/agingdr" />
                    </MenuItem>
                  )}
                  {menu.includes("AgingCr") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Aging Payables
                      <Link to="/agingcr" />
                    </MenuItem>
                  )}
                  {menu.includes("Receivables") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Receivables
                      <Link to="/recables" />
                    </MenuItem>
                  )}
                  {menu.includes("Payables") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Payables
                      <Link to="/payables" />
                    </MenuItem>
                  )}
                  {menu.includes("A/C P/L") && (
                    <MenuItem icon={<FaAngleRight />}>
                      A/C P/L
                      <Link to="/acpl" />
                    </MenuItem>
                  )}
                  {menu.includes("Balance Sheet") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Balance Sheet
                      <Link to="/bs2" />
                    </MenuItem>
                  )}
                  {menu.includes("Dr Dues") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Dr Dues
                      <Link to="/drdues" />
                    </MenuItem>
                  )}
                  {menu.includes("Cr Dues") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Cr Dues
                      <Link to="/crdues" />
                    </MenuItem>
                  )}
                  {menu.includes("Analysis") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Analysis
                      <Link to="/ana" />
                    </MenuItem>
                  )}
                  {menu.includes("Management Report") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Management Report
                      <Link to="/management" />
                    </MenuItem>
                  )}
                  {menu.includes("Trial") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Chart of A/C
                      <Link to="/coa" />
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {localStorage.getItem("user") && menu.includes("Stk Reports") && (
                <SubMenu
                  // suffix={<span className="badge yellow">3</span>}
                  title="Stk Reports"
                  icon={<FaWarehouse />}
                  className="hover_underline"
                >
                  {menu.includes("Product Ledger") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Product Ledger
                      <Link to="/prodleg" />
                    </MenuItem>
                  )}
                  {menu.includes("Yard Product Ledger") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Yard Product Ledger
                      <Link to="/yardprodleg" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Level") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Stock Level
                      <Link to="/stklevel" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Evaluation") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Stock Evaluation
                      <Link to="/stkeval" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Purchases") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Purchase Report
                      <Link to="/stkpur" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Sales") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Sale Report
                      <Link to="/stksal" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk P/L") && (
                    <MenuItem icon={<FaAngleRight />}>
                      PL Report
                      <Link to="/stkpl" />
                    </MenuItem>
                  )}
                  {contextdata?.acSettings[0]?.PurThroughPO ?
                    menu.includes("Stk PO") && (
                      <>
                        <MenuItem icon={<FaAngleRight />}>
                          Pending PO
                          <Link to="/stkpendpo" />
                        </MenuItem>
                        <MenuItem icon={<FaAngleRight />}>
                          PO Ledger
                          <Link to="/poleg/0" />
                        </MenuItem>
                      </>
                    ) : ""}
                </SubMenu>
              )}
              {localStorage.getItem("user") && menu.includes("Yarn Reports") && (
                <SubMenu
                  // suffix={<span className="badge yellow">3</span>}
                  title="Stk Reports"
                  icon={<FaWarehouse />}
                  className="hover_underline"
                >
                  {menu.includes("Product Ledger") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Product Ledger
                      <Link to="/yrprodleg" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Level") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Stock Level
                      <Link to="/yrstklevel" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Evaluation") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Stock Evaluation
                      <Link to="/yrstkeval" />
                    </MenuItem>
                  )}
                  {menu.includes("Yarn In Report") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Purchase Report
                      <Link to="/yrpur" />
                    </MenuItem>
                  )}
                  {menu.includes("Yarn Out Report") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Sale Report
                      <Link to="/yrsal" />
                    </MenuItem>
                  )}
                  {menu.includes("Yarn P/L") && (
                    <MenuItem icon={<FaAngleRight />}>
                      PL Report
                      <Link to="/yrpl" />
                    </MenuItem>
                  )}
                </SubMenu>
              )}              
              {localStorage.getItem("user") && menu.includes("Dig Reports") && (
                <SubMenu
                  // suffix={<span className="badge yellow">3</span>}
                  title="Dig Reports"
                  icon={<FaWarehouse />}
                  className="hover_underline"
                >
                  {menu.includes("Product Ledger") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Digital Id Ledger
                      <Link to="/digidleg/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Product Ledger") && (
                    <MenuItem icon={<FaAngleRight />}>
                      BarCode Ledger (H.O)
                      <Link to="/digbarcodeleg/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Product Ledger (POS)") && (
                    <MenuItem icon={<FaAngleRight />}>
                      BarCode Ledger (POS)
                      <Link to="/digbarcodelegpos/0" />
                    </MenuItem>
                  )}
                  {menu.includes("Product Ledger") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Product Ledger (H.O)
                      <Link to="/digprodleg" />
                    </MenuItem>
                  )}
                  {menu.includes("Product Ledger (POS)") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Product Ledger (POS)
                      <Link to="/digprodlegpos" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Level") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Stock (H.O)
                      <Link to="/digstklevel" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Level (POS)") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Stock (POS)
                      <Link to="/digstklevelpos" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Purchases") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Purchase Report
                      <Link to="/digpur" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Sales") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Sale Report
                      <Link to="/digsal" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Sales 2") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Sale Report
                      <Link to="/digsal?ttype=dsv2" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Purchases") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Digital P/L (H.O)
                      <Link to="/digpl" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Purchases (POS)") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Digital P/L (POS)
                      <Link to="/digplpos" />
                    </MenuItem>
                  )}
                  {menu.includes("Stk Purchases") && (
                    <MenuItem icon={<FaAngleRight />}>
                      Consumption Report (H.O)
                      <Link to="/digout" />
                    </MenuItem>
                  )}

                  {contextdata?.acSettings[0]?.PurThroughPO &&
                    menu.includes("Stk PO") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Pending PO
                        <Link to="/stkpendpo" />
                      </MenuItem>
                    )}
                </SubMenu>
              )}
              {localStorage.getItem("user") &&
                menu.includes("Greige Reports") && (
                  <SubMenu
                    // suffix={<span className="badge yellow">3</span>}
                    title="Greige Reports"
                    icon={<FaWarehouse />}
                    className="hover_underline"
                  >
                    {menu.includes("Contract Status") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Contract Status
                        <Link to="/contstat" />
                      </MenuItem>
                    )}
                    {menu.includes("Yarn Purchase Reports") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Godown Ledger
                        <Link to="/godownleg" />
                      </MenuItem>
                    )}
                    {menu.includes("Godown Stock") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Godown Stock
                        <Link to="/godownstk" />
                      </MenuItem>
                    )}
                    {menu.includes("Yarn Ledger") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Yarn Ledger
                        <Link to="/yarnleg" />
                      </MenuItem>
                    )}
                    {menu.includes("Yarn Stock") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Yarn Stock
                        <Link to="/yarnstk" />
                      </MenuItem>
                    )}
                    {menu.includes("Weaving Ledger") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Weaving Ledger
                        <Link to="/weavingleg" />
                      </MenuItem>
                    )}
                    {menu.includes("Weaving Stock") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Weaving Stock
                        <Link to="/weavingstk" />
                      </MenuItem>
                    )}
                    {menu.includes("Pending Greige Contracts") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Pending Greige Contracts
                        <Link to="/pendcontracts" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Ledger") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Ledger
                        <Link to="/greigeleg" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Stock") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Stock
                        <Link to="/greigestk" />
                      </MenuItem>
                    )}
                    {menu.includes("Weaving Ledger") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Lot Ledger
                        <Link to="/wgrlotleg" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Ledger Report") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Lot Ledger
                        <Link to="/grlotleg/0" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Stock Report") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Stock
                        <Link to="/grstk" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Stock Report") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige In/Out Stock
                        <Link to="/grinoutstk" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Receive Report") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Receive Report
                        <Link to="/grpur" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Issue Report") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Issue Report
                        <Link to="/griss" />
                      </MenuItem>
                    )}
                    {menu.includes("Fresh Sales Report") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Fresh Sales Report
                        <Link to="/fsal" />
                      </MenuItem>
                    )}

                    {/* GREIGE STORE REPORTS */}

                    {menu.includes("Store Product Ledger") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Store Product Ledger
                        <Link to="/digprodleg" />
                      </MenuItem>
                    )}
                    {menu.includes("Store Stock Report") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Store Purchases
                        <Link to="/digpur" />
                      </MenuItem>
                    )}

                    {menu.includes("Store Stock Report") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Store Stock Report
                        <Link to="/digstklevel" />
                      </MenuItem>
                    )}

                    {menu.includes("Store Consumption Report") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Store Consumption Report
                        <Link to="/digout" />
                      </MenuItem>
                    )}
                  </SubMenu>
                )}
              {localStorage.getItem("user") &&
                menu.includes("Greige Reports") && (
                  <SubMenu
                    // suffix={<span className="badge yellow">3</span>}
                    title="Transaction Reports"
                    icon={<FaFileAlt />}
                    className="hover_underline"
                  >
                    {menu.includes("Yarn Purchase Reports") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Yarn Purchase Reports
                        <Link to="/yarnpur" />
                      </MenuItem>
                    )}
                    {menu.includes("Yarn Transfer Reports") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Yarn Transfer Reports
                        <Link to="/yarntrans" />
                      </MenuItem>
                    )}
                    {menu.includes("Yarn Sale Reports") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Yarn Sale Reports
                        <Link to="/yarnout" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Contract Reports") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Contract Reports
                        <Link to="/grcont" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Purchase Reports") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Purchase Reports
                        <Link to="/grpurch" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Receive Reports") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Receive Reports
                        <Link to="/grrec" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige Sale Reports") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige Sale Reports
                        <Link to="/grsal" />
                      </MenuItem>
                    )}
                    {menu.includes("Greige PL Reports") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Greige PL Reports
                        <Link to="/wvstkpl" />
                      </MenuItem>
                    )}
                    {menu.includes("Digital Processing") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Digital Processing Pend Bills
                        <Link to="/digprocpendbills" />
                      </MenuItem>
                    )}
                    {menu.includes("Audit Reports") && (
                      <MenuItem icon={<FaAngleRight />}>
                        Audit Reports
                        <Link to="/wvaudit" />
                      </MenuItem>
                    )}
                  </SubMenu>
                )}
              {localStorage.getItem("user") && (
                <MenuItem onClick={SignOut} icon={<FiLogOut />}>
                  Logout
                </MenuItem>
              )}
              {!localStorage.getItem("user") && (
                <>
                  <MenuItem
                    onClick={() => navigate("/login")}
                    icon={<FaSignInAlt />}
                  >
                    Login
                  </MenuItem>
                  <MenuItem
                    onClick={() => alert("Contact Us to create company")}
                    icon={<FaUserPlus />}
                  >
                    Register
                  </MenuItem>
                </>
              )}
            </Menu>
          </SidebarContent>
          {localStorage.getItem("user") &&
            menu.includes("Daily Audit Register") && (
              <SidebarFooter>
                <Menu iconShape="square">
                  <MenuItem icon={<FaCalculator />}>
                    Daily Audit Register
                    <Link to="/dailyregaudit" />
                  </MenuItem>
                </Menu>
              </SidebarFooter>
            )}
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBar;
